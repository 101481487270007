import React, { useState, useEffect } from "react";
import QrReader from "react-qr-scanner";

const Scanner = () => {
	const [result, setResult] = useState("No result");
	const [ticketDetails, setTicketDetails] = useState(null);
	const [scanning, setScanning] = useState(true);

	useEffect(() => {
		// Redirect to login if token is not set or expired
		const token = localStorage.getItem("token");
		const expiresIn = localStorage.getItem("expiresIn");

		if (!token || Date.now() >= expiresIn) {
			window.location.href = "/login";
		}
	}, []);

	const handleScan = async (data) => {
		if (data === null) {
			return;
		}
		if (result === data.text) {
			return;
		}

		if (data) {
			setResult(data.text);

			// send a GET request to get the ticket details
			fetch("https://hangoverparties.sk/api/tickets/" + data.text, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: localStorage.getItem("token"),
				},
			})
				.then((response) => response.json())
				.then((ticketData) => {
					setTicketDetails(ticketData); // Store ticket details
					setScanning(false);
					console.log(ticketData);
				})
				.catch((error) => {
					alert("Ticket not found");
					console.error("Error fetching ticket:", error);
				});

			// send a PATCH request to update the ticket status as scanned
			fetch("https://hangoverparties.sk/api/tickets/" + data.text, {
				method: "PATCH",
				headers: {
					"Content-Type": "application/json",
					Authorization: localStorage.getItem("token"),
				},
				body: JSON.stringify({
					scanned: true,
				}),
			})
				.then((response) => response.json())
				.then((ticketData) => {
					// setTicketDetails(ticketData);
					// stop the scanner after a successful scan

					console.log(ticketData);
				})
				.catch((error) => {
					console.error("Error updating ticket:", error);
				});

			// fetch("https://hangoverparties.sk/api/tickets/" + data.text + "/scan", {
			// 	method: "GET",
			// 	headers: {
			// 		"Content-Type": "application/json",
			// 		Authorization: localStorage.getItem("token"),
			// 	},
			// })
			// 	.then((response) => response.json())
			// 	.then((ticketData) => {
			// 		setTicketDetails(ticketData);

			// 		console.log(ticketData);
			// 	})
			// 	.catch((error) => {
			// 		console.error("Error updating ticket:", error);
			// 	});
		}
	};

	const handleError = (err) => {
		console.error("QR Scan Error:", err);
	};

	return (
		<div className="p-4">
			<h1 className="p-4 text-2xl font-bold text-center">Ticket Scanner</h1>

			{scanning && (
				<QrReader
					delay={300}
					onError={handleError}
					onScan={handleScan}
					style={{ width: "100%" }}
					constraints={{
						video: { facingMode: "environment" }, // Prefer rear camera if available
					}}
				/>
			)}

			{result !== "No result" && ticketDetails && (
				<div className="popup p-4 shadow-lg rounded-md bg-gray-100">
					<h2 className="text-lg font-semibold text-center">Ticket Details</h2>
					<p>
						<strong>Ticket ID:</strong> {ticketDetails.id}
					</p>
					<p>
						<strong>Event:</strong> {ticketDetails.eventId}
					</p>
					<p>
						<strong>Type:</strong> {ticketDetails.ticketTypeName}
					</p>
					{ticketDetails.scannedAt ? (
						<div className="bg-red-600 p-4 rounded-lg mt-5">
							<h2 className="text-center font-bold text-xl p-2">Ticket uz naskenovany</h2>
							<p>
								<strong>Scanned At:</strong> {new Date(ticketDetails.scannedAt).toLocaleString()}
							</p>
						</div>
					) : (
						<div className="bg-green-600 p-4 rounded-lg mt-5">
							<h2 className="text-center font-bold text-xl p-2">Ticket je valid</h2>
						</div>
					)}
				</div>
			)}

			<button
				onClick={() => window.location.reload()}
				className="block my-4 text-center mx-auto border-2 border-gray-600 rounded-md font-semibold text-lg px-4 py-2"
			>
				Scan next
			</button>
			<button
				onClick={() => (window.location.href = "/")}
				className="block text-center mx-auto border-2 border-gray-600 rounded-md font-semibold text-lg px-4 py-2 mb-20"
			>
				Back
			</button>
		</div>
	);
};

export default Scanner;
