import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Home() {
	const [MyEvent, setMyEvent] = useState({});
	const [Tickets, setTickets] = useState([]);
	const [scannedTickets, setScannedTickets] = useState([]);
	const [totalTickets, setTotalTickets] = useState(0);

	useEffect(() => {
		// Redirect to login if token is not set or expired
		const token = localStorage.getItem("token");
		const expiresIn = localStorage.getItem("expiresIn");

		if (!token || Date.now() >= expiresIn) {
			window.location.href = "/login";
		}

		const checkCameraPermission = async () => {
			try {
				const permission = await navigator.permissions.query({ name: "camera" });
				// console.log("Camera permission:", permission.state);

				permission.onchange = () => {
					// console.log("Camera permission:", permission.state);
				};
			} catch (error) {
				console.error("Error checking camera permission:", error);
			}
		};

		// get myEvent from hangoverparties.sk/events/upcoming
		const getEvent = async () => {
			try {
				const response = await fetch("https://hangoverparties.sk/api/events/upcoming");
				const data = await response.json();
				setMyEvent(data);
				fetch("https://hangoverparties.sk/api/tickets?eventID=" + data.id, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: localStorage.getItem("token"),
					},
				})
					.then((response) => response.json())
					.then((ticketData) => {
						setTickets(ticketData);
						setTotalTickets(ticketData.length);
						const scanned = ticketData.filter((ticket) => ticket.scannedAt);
						setScannedTickets(scanned);
						console.log("Tickets scanned:", scanned.length);
						console.log("Total tickets:", ticketData.length);
					})
					.catch((error) => {
						console.error("Error fetching ticket:", error);
					});
			} catch (error) {
				console.error("Error getting event data:", error);
			}
		};

		checkCameraPermission();
		getEvent();
	}, []);

	return (
		<div className="p-4 pt-20">
			<h1 className="w-full text-center text-3xl font-bold">Hangover Scanner</h1>
			<div className="flex flex-col justify-around p-10 items-center">
				<h1 className="font-semibold text-lg">Event: {MyEvent.name}</h1>
				<h1 className="font-semibold text-lg">
					Tickets: {scannedTickets.length} / {totalTickets}
				</h1>

				<Link to="/scan" className="btn btn-lg btn-neutral my-4 text-center">
					<button className="border-2 border-gray-500 rounded-lg p-2">Scan Ticket</button>
				</Link>

				{/* <h2 className="text-xl font-semibold text-center p-4">Event details</h2> */}
			</div>
			{/* logout button */}
			<div className="flex justify-center">
				<button
					className="btn btn-lg btn-neutral"
					onClick={() => {
						localStorage.removeItem("token");
						window.location.href = "/login";
					}}
				>
					Logout
				</button>
			</div>
		</div>
	);
}

export default Home;
