// src/pages/Login.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function Login() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error] = useState("");

	const navigate = useNavigate();

	const handleLogin = async (e) => {
		e.preventDefault();

		if (!email || !password) {
			alert("Please fill in all fields");
			return;
		}

		login();
	};

	function login() {
		const username = email;
		const userPassword = password;

		// send a POST request to the endpoint
		fetch(
			"https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=AIzaSyAlZb9TCG0mod3uDg-r3u9ZDedCwGvtQ-o",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					email: username,
					password: userPassword,
					returnSecureToken: true,
				}),
			}
		)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);

				if (data.error) {
					alert(data.error.message);
					return;
				}
				// save the token in the cookie and delete after 3600 seconds
				// delete token first
				localStorage.removeItem("token");
				localStorage.setItem("token", data.idToken);

				localStorage.setItem("expiresIn", Date.now() + data.expiresIn * 1000);
				navigate("/");
			})
			.catch((error) => {
				console.error(error);
			});
	}

	return (
		<div className="p-4 pt-10">
			<h1 className="w-full text-white text-center text-3xl font-bold pb-3">Login</h1>
			{error && <p className="p-2 text-red-500">{error}</p>}
			<form onSubmit={handleLogin} className="flex flex-col items-center ">
				<input
					className="input input-bordered w-full max-w-md mb-4 border-gray-600 border-2 rounded p-2"
					type="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					placeholder="Email"
				/>
				<input
					className="input input-bordered w-full max-w-md mb-4 border-gray-600 border-2 rounded p-2"
					type="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					placeholder="Password"
				/>
				<button className="btn btn-md btn-primary" type="submit">
					Login
				</button>
			</form>
		</div>
	);
}

export default Login;
